import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import cookies from "../lib/cookies";

export const useLoggedIn = () => {
  // might need to look at this if we see issues https://github.com/reactivestack/cookies/pull/288#issuecomment-895672629
  const [currentCookies] = useCookies([cookies.options.access_token]);
  const [loggedIn, setLoggedIn] = useState(
    !!currentCookies[cookies.options.access_token],
  );

  useEffect(() => {
    const access_token = currentCookies[cookies.options.access_token];
    setLoggedIn(!!access_token);
  }, [currentCookies]);

  return loggedIn;
};
