import { useLoggedIn } from "#/hooks/use-logged-in";
import type { RumPublicApi } from "@datadog/browser-rum-core";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

declare global {
  interface Window {
    DD_RUM?: RumPublicApi & {
      startView?(name?: string): void;
    };
  }
}

export default function RootPage() {
  const isLoggedIn = useLoggedIn();
  const router = useRouter();

  useEffect(() => {
    if (isLoggedIn) {
      router.replace("/menu");
    } else {
      router.replace("/welcome");
    }
  }, [isLoggedIn, router]);

  return <></>;
}
